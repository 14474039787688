<!--
 * @Descripttion : 用户查询
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-31 21:12:37
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-14 10:26:24
-->
<template>
    <div>
        <div style="padding:10px 0;">
            <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
                <a-form-model-item>
                    <a-input-search v-model="formInline.keyWord" placeholder="昵称" allowClear enter-button @search="getUserList" />
                </a-form-model-item>
                <a-form-model-item v-if="permissions.indexOf('sys:user:add')>-1">
                    <a-button type="primary" icon="plus" @click="addUser" />
                </a-form-model-item>
            </a-form-model>
        </div>

        <a-radio-group v-model="formInline.status" @change="onChangeStatus" style="margin-bottom:10px;">
            <a-radio-button value="">
                全部
            </a-radio-button>
            <a-radio-button :value="0">
                无效
            </a-radio-button>
            <a-radio-button :value="1">
                正常
            </a-radio-button>
            <a-radio-button :value="2">
                锁定
            </a-radio-button>
        </a-radio-group>

        <a-table :columns="columns" bordered :data-source="data" :rowKey='record=>record.id' :pagination="pagination" :loading="loading" @change="handleTableChange" size="middle">
            <template slot="status" slot-scope="record">
                <div v-if="record.status==0">
                    无效
                </div>
                <div v-if="record.status==1">
                    正常
                </div>
                <div v-if="record.status==2">
                    锁定
                </div>
            </template>

            <span slot="operation" slot-scope="text, record">
                <template>
                    <a-dropdown>
                        <a class="ant-dropdown-link"> 操作
                            <a-icon type="down" />
                        </a>
                        <a-menu slot="overlay">
                            <a-menu-item v-if="permissions.indexOf('sys:user:edit') > -1" @click="editUser(record)">编辑</a-menu-item>
                            <a-menu-item v-if="permissions.indexOf('sys:user:resetPassword') > -1" @click="onSubmitResetPassword(record)">重置密码</a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </template>
            </span>

            <!-- <template slot="operation" slot-scope="record">
        <a-space>
          <a-button type="primary" size="small" v-if="permissions.indexOf('sys:user:edit')>-1" icon="edit" @click="editUser(record)" />
        </a-space>
      </template> -->
        </a-table>

        <!-- 新增/编辑用户 -->
        <a-drawer :title="title" :width="300" :visible="visibleUser" @close="onClose">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item label="用户名" prop="username">
                    <a-input v-model="form.username" placeholder="请输入用户名" :disabled="form.id!==''" />
                </a-form-model-item>
                <template v-if="form.id===''">
                    <a-form-model-item label="密码" prop="password">
                        <a-input v-model="form.password" placeholder="请输入密码" />
                    </a-form-model-item>
                </template>
                <a-form-model-item label="昵称" prop="nickname">
                    <a-input v-model="form.nickname" placeholder="请输入昵称" />
                </a-form-model-item>
                <a-form-model-item label="手机号" prop="phone">
                    <a-input v-model="form.phone" placeholder="请输入手机号" />
                </a-form-model-item>
                <a-form-model-item label="电话" prop="telephone">
                    <a-input v-model="form.telephone" placeholder="请输入电话" />
                </a-form-model-item>
                <a-form-model-item label="邮箱" prop="email">
                    <a-input v-model="form.email" placeholder="请输入邮箱" />
                </a-form-model-item>
                <a-form-model-item label="生日" prop="birthday">
                    <a-date-picker v-model="form.birthday" :format="dateFormat" placeholder="请选择生日" :showToday="false" @change="onChange">
                    </a-date-picker>
                </a-form-model-item>
                <a-form-model-item label="性别" prop="sex">
                    <a-radio-group name="sex" v-model="form.sex">
                        <a-radio :value="1">
                            男
                        </a-radio>
                        <a-radio :value="0">
                            女
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <template v-if="form.id!==''">
                    <a-form-model-item label="状态" prop="status">
                        <a-radio-group name="status" v-model="form.status">
                            <a-radio :value="0">
                                无效
                            </a-radio>
                            <a-radio :value="1">
                                正常
                            </a-radio>
                            <a-radio :value="2">
                                锁定
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </template>
                <a-form-model-item label="角色" prop="roleIds">
                    <div style="max-height:200px;background-color:#f1f1f1;overflow: auto;padding: 5px;margin-bottom:10px;">
                        <a-checkbox-group v-model="form.roleIds">
                            <template v-for="(item,index) in roleList">
                                <a-checkbox :value="item.id" :key="index">
                                    {{item.name}}
                                </a-checkbox>
                            </template>
                        </a-checkbox-group>
                    </div>
                </a-form-model-item>
            </a-form-model>
            <div class="ml_drawer_footer">
                <a-button @click="onClose">
                    取消
                </a-button>
                <a-button style="margin-left: 10px" type="primary" @click="onSubmit">
                    确定
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>
<script>
import {
    AddUserInfo,
    getUserList,
    updateUserInfo,
    getUserInfoByID,
    resetPassword
} from "@/api/users";

import { getRoleByUserID } from "@/api/system";

import { getRoleAll } from "@/api/system";
import { mapState } from "vuex";

import moment from "moment";

import { validatePhone, validatePhoneTwo } from "@/utils/verification";

const columns = [
    { title: "登录名", dataIndex: "username", align: "center" },
    { title: "昵称", dataIndex: "nickname", align: "center" },
    { title: "手机号", dataIndex: "phone", align: "center" },
    { title: "邮箱", dataIndex: "email", align: "center" },
    { title: "状态", scopedSlots: { customRender: "status" }, align: "center" },
    {
        title: "操作",
        scopedSlots: { customRender: "operation" },
        align: "center"
    }
];

export default {
    data() {
        return {
            columns,
            data: [],
            loading: false,
            pagination: {
                pageSize: 10,
                pageSizeOptions: ["10", "20", "30", "40"],
                showSizeChanger: true,
                current: 1,
                total: 0
            },
            visibleUser: false, //  是否显示操作
            formInline: {
                nickname: "",
                status: ""
            },
            title: "",
            form: {
                id: "",
                username: "", //  用户名
                password: "",
                nickname: "", //  昵称
                phone: "", //  手机号
                telephone: "", //  电话
                email: "", //  邮箱
                birthday: null, //  生日
                sex: 1, //  性别
                status: 1,
                type: "platform",
                roleIds: []
            },
            rules: {
                username: [{ required: true, message: "请输入用户名" }],
                password: [{ required: true, message: "请输入密码" }],
                phone: [{ validator: validatePhone, trigger: "change" }],
                telephone: [{ validator: validatePhoneTwo, trigger: "change" }]
            },
            roleList: [],
            dateFormat: "YYYY-MM-DD"
        };
    },
    computed: {
        ...mapState({
            permissions: state => state.userStore.permissions
        })
    },
    mounted() {
        this.getUserList();

        getRoleAll({
            _: new Date().getTime()
        }).then(res => {
            this.roleList = res.data;
        });
    },
    methods: {
        getUserList() {
            //   获取用户列表
            const that = this;
            that.loading = true;
            getUserList({
                nickname: that.formInline.keyWord || null,
                status: that.formInline.status,
                pageNum: that.pagination.current,
                pageSize: that.pagination.pageSize,
                _: new Date().getTime()
            })
                .then(res => {
                    that.loading = false;
                    that.data = res.data.list;
                    that.pagination.total = res.data.total;
                })
                .catch(err => {
                    console.log(err);
                    that.loading = false;
                });
        },
        onChangeStatus() {
            this.pagination.current = 1;
            this.getUserList();
        },
        addUser() {
            const that = this;
            that.title = "新增用户";
            that.form.id = "";
            that.visibleUser = true;

            that.$nextTick(() => {
                that.$refs.ruleForm.resetFields();
            });
        },
        editUser(e) {
            const that = this;
            that.title = "编辑用户";
            that.visibleUser = true;
            that.$nextTick(() => {
                that.$refs.ruleForm.resetFields();
            });
            getUserInfoByID(e.id, {
                _: new Date().getTime()
            }).then(res => {
                that.form.id = res.data.id;
                that.form.username = res.data.username; //  用户名
                that.form.nickname = res.data.nickname; //  昵称
                that.form.phone = res.data.phone; //  手机号
                that.form.telephone = res.data.telephone; //  电话
                that.form.email = res.data.email; //  邮箱
                if (res.data.birthday) {
                    that.form.birthday = moment(res.data.birthday, "YYYY-MM-DD"); //  生日
                }
                that.form.sex = res.data.sex; //  性别
                that.form.status = res.data.status; //  状态
            });

            getRoleByUserID({
                userId: e.id,
                _: new Date().getTime()
            }).then(res => {
                res.data.forEach(element => {
                    that.form.roleIds.push(element.id);
                });
            });
        },
        onSubmit() {
            // 提交角色
            const that = this;
            that.$refs.ruleForm.validate(valid => {
                if (valid) {
                    that.form["json"] = true;
                    if (that.form.id) {
                        updateUserInfo(that.form).then(() => {
                            that.$message.success({
                                content: "修改成功！",
                                duration: 1,
                                onClose: () => {
                                    that.visibleUser = false;
                                    that.getUserList();
                                }
                            });
                        });
                    } else {
                        AddUserInfo(that.form).then(() => {
                            that.$message.success({
                                content: "添加成功！",
                                duration: 1,
                                onClose: () => {
                                    that.visibleUser = false;
                                    that.getUserList();
                                }
                            });
                        });
                    }
                }
            });
        },
        onClose() {
            const that = this;
            that.$refs.ruleForm.resetFields();
            that.visibleUser = false;
        },
        handleTableChange(pagination, filters, sorter) {
            console.log(pagination, filters, sorter);
            this.pagination = { ...pagination };
            this.getUserList();
        },

        onChange(date, dateString) {
            console.log(date);
            this.form.birthday = moment(dateString, "YYYY-MM-DD");
        },
        onSubmitResetPassword(e) {
            const that = this;
            that.$confirm({
                title: '提示',
                content: '您确定重置密码吗？',
                onOk() {
                    that.resetPasswordFn(e.id);
                },
                onCancel() { },
            });
        },
        resetPasswordFn(id) {
            const that = this;
            resetPassword(id).then(() => {
                that.$success({
                    title: '提示',
                    content: '密码已重置，新密码：123456',
                    onOk() {
                        that.getUserList();
                    },
                });
            });
        }
    }
};
</script>
<style lang="scss" >
</style>